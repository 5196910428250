<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">帖子列表</h4>
            <div class="row mt-4">
              <div style="display: flex;margin-bottom: 20px;width: 300px;">

                <!-- <el-input v-model="searchInput" placeholder="请输入帖子名称"></el-input>
                <el-button @click="SearchParent">搜索</el-button> -->

                <el-button @click="AddComm()" type="success">发布帖子</el-button>
              </div>

            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" per-page=0 :current-page="currentPage"
                :sort-by.sync="sortBy" :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(img_url)="data">
                  <span v-html="data.value"></span>
                </template>
                <template v-slot:cell(piaoxi)="data">
                  <b-badge style="    padding: 5px 10px;
                  margin-right: 5px;" variant="primary" v-for="(item,idx) in data.value" :key="idx">{{item}}</b-badge>
                </template>
                

                <template v-slot:cell(tool)="data">
                  <b-button @click="EditRows(data.item,data.index)" style="margin-right: 10px;" variant="warning">编辑
                  </b-button>
                  <b-button @click="delRows(data.item.id,data.index)" style="margin-right: 10px;" variant="danger">删除
                  </b-button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>

    <el-dialog title="发布帖子" :visible.sync="modalShow" top="15px" width="40%" :before-close="handleClose">
      <el-form ref="form" :model="AddCommData" label-width="80px">
        <el-form-item label="帖子内容">
          <el-input type="textarea" v-model="AddCommData.title" :rows="3"></el-input>
        </el-form-item>
        <el-form-item label="帖子分类">
          <el-select v-model="AddCommData.fenlei" placeholder="请选择帖子分类">
            <el-option label="出票" value="出票"></el-option>
            <el-option label="收票" value="收票"></el-option>
            <el-option label="逾期" value="逾期"></el-option>
            <el-option label="预警" value="预警"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="帖子图片">
          <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :auto-upload="false"
            :multiple="false" ref="upload" :limit="1" :on-remove="handleRemove" :http-request="httpRequest"
            :file-list="fileList" list-type="picture">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5mb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="票系">
          <div style="display: flex;">
            <el-input style="width: 100px;margin-right: 10px;" v-for="(item,idx) in AddCommData.piaoxi" :key="idx"
              v-model="AddCommData.piaoxi[idx].piaoxi"></el-input>
          </div>

        </el-form-item>

        <el-form-item label="承兑人">
          <el-input v-model="AddCommData.chengduir"></el-input>
        </el-form-item>

        <el-form-item label="用户名">
          <el-input v-model="AddCommData.username"></el-input>
        </el-form-item>

        <el-form-item label="用户头像">
          <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :auto-upload="false"
            :multiple="false" ref="AvatarUpload" :limit="1" :on-remove="AvatarhandleRemove"
            :http-request="AvatarhttpRequest" :file-list="AvatarfileList" list-type="picture">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5mb</div>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="AddCommSubmit">发布</el-button>
          <el-button @click="modalShow = false">取消</el-button>
        </el-form-item>


      </el-form>
    </el-dialog>
  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  // import { tableData } from "./dataAdvancedtable";
  /**
   * Advanced table component
   */
  export default {
    page: {
      title: "帖子列表",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        fileList: [],
        AvatarfileList: [],
        modalShow: false,
        tableData: '',
        title: "帖子列表",
        items: [
          {
            text: "首页",
            href: "/"
          },
          {
            text: "帖子列表",
            active: true
          }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: [
          { key: "nike_name", label: "用户名" },
          { key: "title", label: "帖子内容" },
          {
            key: "img_url", label: "图片", valign: 'middle', formatter(value, key, item) {
              var result = "";
              if (value) {
                result = `<a class = "view" target="_blank"  href="${utils.CommImg_Url + value}"><img style="width:70px;height:70px;border: 1px solid #d7d7d7;
    padding: 2px;"   src="${utils.CommImg_Url + value}" /></a>`;
              }
              return result;
            }, events: 'operateEvents'
          },
          { key: "piaoxi", label: "票系" },
          { key: "chengduir", label: "承兑人" },
          { key: "go_time", label: "发布时间", sortable: true },
          {
            key: "tool", label: "工具栏"
          },
        ],
        dialogVisible: false,
        LogoFile: "",
        AddCommData: {
          fenlei: '出票',
          piaoxi: [
            { piaoxi: "" },
            { piaoxi: "" },
            { piaoxi: "" },
          ],
          title: '',
          chengduir: '',
          username: "",
          img_file: "",
          Avatar_file: ""
        },
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: "",
        uuid:""
      };
    },
    computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
    },
    created() {
      this.uuid = this.$route.params
      console.log(this.uuid )
      this.CheckParentCompany()

      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },

    methods: {
      // 发布帖子
      AddCommSubmit() {
        this.$refs.upload.submit();
        this.$refs.AvatarUpload.submit();
        let PxList = []
        this.AddCommData.piaoxi.map((item, idx) => {
          if (item.piaoxi != "") {
            PxList.push(item.piaoxi)
          }
        })
        let fd = new FormData()// FormData 对象
        fd.append('file', this.AddCommData.img_file)
        fd.append('user_img', this.AddCommData.Avatar_file)
        fd.append('fenlei', this.AddCommData.fenlei)
        fd.append('piaoxi', JSON.stringify(PxList))
        fd.append('title', this.AddCommData.title)
        fd.append('chengduir', this.AddCommData.chengduir)
        fd.append('name', this.AddCommData.username)
        _API_.AddComm(fd)
          .then((res) => {
            utils.ResMsg(res, () => {
              if (res.code == 200) {
                this.$refs.upload.clearFiles();
                this.$refs.AvatarUpload.clearFiles();
                this.AddCommData = {
                  fenlei: '出票',
                  piaoxi: [
                    { piaoxi: "" },
                    { piaoxi: "" },
                    { piaoxi: "" },
                  ],
                  title: '',
                  chengduir: '',
                  username: "",
                  img_file: "",
                  Avatar_file: ""
                }
              }
            })
          })
      },
      handleRemove() {
        this.AddCommData.img_file = "" // 相当于input里取得的files
        return false
      },
      httpRequest(param) {
        this.AddCommData.img_file = param.file // 相当于input里取得的files
        return false
      },
      AvatarhandleRemove() {
        this.AddCommData.Avatar_file = "" // 相当于input里取得的files
        return false
      },
      AvatarhttpRequest(param) {
        this.AddCommData.Avatar_file = param.file // 相当于input里取得的files
        return false
      },
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {
          this.onSubmit(); //登录事件
        }
      },
      // 搜索
      SearchParent() {
        console.log(123)
        _API_.get_parent_company({
          name: this.searchInput
        }).then((res) => {
          this.page.total = 10
          this.tableData = res.data
        })
      },
      onSubmit1() {
        let that = this
        let fd = new FormData()// FormData 对象
        fd.append('id', this.EditRowData.id)// 文件对象
        fd.append('name', this.EditRowData.name)// 文件对象
        fd.append('piaoxi', this.EditRowData.piaoxi)// 文件对象
        fd.append('leib', this.EditRowData.leib)// 文件对象
        fd.append('Listed_of', this.EditRowData.Listed_of ? 1 : 0)// 文件对象
        fd.append('Listing_number', this.EditRowData.Listing_number)// 文件对象
        fd.append('enterprise', this.EditRowData.enterprise)// 文件对象
        fd.append('guojpj', this.EditRowData.guojpj)// 文件对象
        fd.append('guonpj', this.EditRowData.guonpj)// 文件对象

        if (this.LogoFile) {

          fd.append('img_url', this.LogoFile)// 文件对象
        }

        _API_.ParentCompanyEdit(fd).then((res) => {

          if (res.code == 200) {
            this.$set(this.tableData, this.Editindex, res.data[0])
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 1000,
              onClose() {
                that.modalShow = false
              }
            });
            document.getElementById('LogoFile').outerHTML = document.getElementById('LogoFile').outerHTML
            this.
              this.LogoFile = ""
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1000
            });
            document.getElementById('LogoFile').outerHTML = document.getElementById('LogoFile').outerHTML
            this.LogoFile = ""
          }

        })


      },
      EditLogo() {
        this.$refs.LogoUploads.dispatchEvent(new MouseEvent('click'))
      },
      ChangeLogo() {
        this.LogoFile = this.$refs.LogoUploads.files[0]
      },
      handleClose() {
        this.modalShow = false
      },
      //  删除
      delRows(item,DelIdx) {
        _API_.UserComm({
         id:item
        }).then((res) => {
          this.tableData = res.data
          this.page.total = res.count
          this.CheckParentCompany()
        })
      },
      EditRows(Item, Editindex) {
        this.modalShow = true
        this.Editindex = Editindex
        Item.Listed_of = Item.Listed_of == 1 ? true : false
        console.log(Item.Listed_of)
        this.EditRowData = Item
      },
      AddComm() {
        this.modalShow = true
      },
      ModalTrue() {
        this.modalShow = false
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      CheckParentCompany() {
        _API_.UserComm({
          page: this.page.currentPage,
          size: this.page.pageSize,
        }).then((res) => {
          this.tableData = res.data
          this.page.total = res.count
        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }
</style>